/**
 * HelmetWrapper
 * */

import React from "react";
import Helmet from "react-helmet";

function HelmetWrapper({ title, faviconURL, children }) {
  return (
    <React.Fragment>
      <Helmet>
        {faviconURL && (
          <link rel="icon" type="image/x-icon" href={faviconURL} />
        )}
        <title translate="no">{title && `${title} | Lynk Platform`}</title>
      </Helmet>
      {children}
    </React.Fragment>
  );
}

export default HelmetWrapper;
