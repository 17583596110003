import React from "react";
// import _ from "lodash";
import SignupMagicLink from "../../containers/SignupMagicLink";
import LoginMagicLynk from "../../containers/LoginMagicLynk";
import SignupSetup from "../../containers/Signup";
// Will include in later phase - self signup
// import SignupSetup from "containers/SignupSetup";
// import SignupPersonal from "containers/SignupPersonal";
import { WELCOME, SETUP, PERSONAL } from "../../utils/constants";

export const getScreen = (step, magicCodeFormData, existingKP, props) => {
  if (existingKP) {
    return getStepScreenExistingMagicLink(
      step,
      magicCodeFormData,
      props.setIsAuthenticated,
      props.siteKey
    );
  }
  return getStepScreenMagicLink(
    step,
    magicCodeFormData,
    props.setIsAuthenticated
  );
};

export const getStepScreenMagicLink = (
  step,
  magicCodeFormData,
  setIsAuthenticated
) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case WELCOME:
      return {
        title: "Sign up",
        track: "Magic Lynk Welcome",
        screen: (
          <SignupMagicLink
            magicCodeFormData={magicCodeFormData}
            setIsAuthenticated={setIsAuthenticated}
          />
        ),
      };
    case SETUP:
      return {
        title: "Signup LYNK - Join Lynk now for free!",
        track: "Magic Lynk Account",
        screen: <SignupSetup setIsAuthenticated={setIsAuthenticated} />,
      };
    case PERSONAL:
      return {
        title: "Signup LYNK - Personal Information",
        track: "Magic Lynk Personal",
        // screen: <SignupPersonal />,
      };
  }
};

export const getStepScreenExistingMagicLink = (
  step,
  magicCodeFormData,
  setIsAuthenticated,
  siteKey
) => {
  // eslint-disable-next-line default-case
  switch (step) {
    case WELCOME: {
      return {
        title: "Login LYNK",
        track: "Magic Lynk Welcome",
        screen: (
          <LoginMagicLynk
            setIsAuthenticated={setIsAuthenticated}
            magicCodeFormData={magicCodeFormData}
            siteKey={siteKey}
          />
        ),
      };
    }
  }
};
