import React from "react";
import styles from "./styles.module.scss";
import logoImage from "../../images/logo.svg";

const ErrorFrame = () => (
  <div>
    <div className={styles.header}>
      <a href="/">
        <img alt="logo" className={styles.logo} src={logoImage} />
      </a>
    </div>
    <div className={styles.container}>
      <h4 className={styles.textCenter}>Page not found</h4>
      <p>
        Sorry, we couldn&apos;t find the page that you were looking for. The
        page might have been moved or the URL is invalid.
      </p>
      <div className={styles.strong}>Need Help?</div>
      <ul className={styles.suggestions}>
        <li>
          If you would like to sign up with Lynk,{" "}
          <a href="mailto:members@lynkpeople.com">contact our team</a> to assist
          you.
        </li>
        <li>
          Return to our{" "}
          <a target="_blank" href="https://lynk.global/" rel="noreferrer">
            home page
          </a>{" "}
          and use the navigation menu to find what you need
        </li>
      </ul>
    </div>
    <div className={styles.footer}>
      © {new Date().getFullYear()}, TSN Holdings. All Rights Reserved.
    </div>
  </div>
);

export default ErrorFrame;
