/* Signup */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HelmetWrapper from "../../components/HelmetWrapper";
import SelfSignup from "../../containers/SelfSignup";
import "./signup.scss";

export default function Signup(props) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [faviconURL, setFaviconURL] = useState("");

  const setCircleFaviconUrl = (favUrl) => {
    setFaviconURL(favUrl);
  };
  const handleLinkedInUserLogin = (result) => {
    if (result.success) {
      props.setIsAuthenticated(true);
      navigate(`/redirect${search}`);
    }
  };

  return (
    <div className="signUpForm" style={{ width: "100%" }}>
      <HelmetWrapper title="Sign up" faviconURL={faviconURL}>
        <div id="signup-form" style={{ width: "100%" }}>
          <SelfSignup
            onLinkedInLogin={handleLinkedInUserLogin}
            setIsAuthenticated={props.setIsAuthenticated}
            siteKey={props.siteKey}
            setCircleFaviconUrl={setCircleFaviconUrl}
          />
        </div>
      </HelmetWrapper>
    </div>
  );
}
