import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import routes from "./routes";
import Loading from "./components/Loading";
import {
  authenticateUser,
  clearCookiee,
  getReCaptchaSiteKeys,
} from "./api/lynkClient";
import Login from "./containers/Login";
import MagicLynk from "./containers/MagicLynk";
import Signup from "./containers/Signup";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import LaSignup from "./containers/lasignup/signup_email";
import Logout from "./containers/Logout";
import _ from "lodash";
import "./App.scss";

function App(props) {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [reCaptchaSiteKeys, setReCaptchaSiteKeys] = useState({});
  useEffect(() => {
    if (new URLSearchParams(search).get("clearCookiee")) {
      // sample URL to clear the cookie: http://localhost:3000/login?isInternalPlatformFlag=true&clearCookiee=true
      clearCookiee()
        .then((response) => {
          // Check the response from auth-service
          setIsAuthenticated(false);
          if (response && response.data.success)
            console.log("Cookiee cleared successfully");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getReCaptchaSiteKeys()
        .then((response) => {
          setReCaptchaSiteKeys(response.data);
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            console.error(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      authenticateUser()
        .then((response) => {
          // Check the response from auth-service
          if (response && _.get(response, "data.success", true))
            setIsAuthenticated(true);
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            console.error(error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [search]);

  if (loading) return <Loading />;

  // If user is authenticated, return protected routes
  if (isAuthenticated) {
    return (
      <Routes>
        {routes.map(({ title, component: Component, url }) => {
          return (
            <Route
              key={url}
              exact
              path={url}
              title={title}
              element={<Component {...props} />}
            />
          );
        })}
        <Route
          exact
          path="/magic-lynk"
          element={
            <MagicLynk
              setIsAuthenticated={setIsAuthenticated}
              siteKey={reCaptchaSiteKeys}
            />
          }
        />
        <Route
          exact
          path="/signup"
          element={
            <Signup
              setIsAuthenticated={setIsAuthenticated}
              siteKey={reCaptchaSiteKeys}
            />
          }
        />
        <Route
          exact
          path="/logout"
          element={
            <Logout
              setIsAuthenticated={setIsAuthenticated}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate replace to={`/redirect${search}`} />}
        />
      </Routes>
    );
  }

  // Else return public routes
  return (
    <Routes>
      {["/login", "/circle/:circleCode/login"].map((path) => (
        <Route
          exact
          path={path}
          element={
            <Login
              setIsAuthenticated={setIsAuthenticated}
              siteKey={reCaptchaSiteKeys}
            />
          }
        />
      ))}
      {["/signup-email"].map((path) => (
        <Route
          exact
          path={path}
          element={<LaSignup setIsAuthenticated={setIsAuthenticated} />}
        />
      ))}
      <Route
        exact
        path="/magic-lynk"
        element={
          <MagicLynk
            setIsAuthenticated={setIsAuthenticated}
            siteKey={reCaptchaSiteKeys}
          />
        }
      />
      {["/signup", "/circle/:circleCode/signup"].map((path) => (
        <Route
          exact
          path={path}
          element={
            <Signup
              setIsAuthenticated={setIsAuthenticated}
              siteKey={reCaptchaSiteKeys}
            />
          }
        />
      ))}
      <Route
        exact
        path="/forgotPassword"
        element={
          <ForgotPassword
            setIsAuthenticated={setIsAuthenticated}
            siteKey={reCaptchaSiteKeys}
          />
        }
      />
      <Route
        exact
        path="/reset-password-confirm"
        element={<ResetPassword setIsAuthenticated={setIsAuthenticated} />}
      />
      <Route
        exact
        path="/logout"
        element={
          <Logout
            setIsAuthenticated={setIsAuthenticated}
            isAuthenticated={isAuthenticated}
          />
        }
      />
      <Route path="*" element={<Navigate replace to={`/login${search}`} />} />
    </Routes>
  );
}

const history = createBrowserHistory();
function AppWithRouter(props) {
  return (
    <div className="App">
      <Router history={history}>
        <App {...props} />
      </Router>
    </div>
  );
}

export default AppWithRouter;
