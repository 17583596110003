import axios from "axios";
const baseUrl = "/";
export const client = axios.create({
  baseUrl,
  withCredentials: true,
});

const validRestStatus = (status) =>
  (status >= 200 && status < 300) || (status >= 400 && status < 500);

export async function loginUser(
  email,
  password,
  captcha,
  captchaInvisible,
  service,
  mfaToken
) {
  return client.post(`auth/login`, {
    email,
    password,
    captcha,
    captchaInvisible,
    service,
    mfaToken,
  });
}

export async function clearCookiee() {
  return client.post("/auth/clearPlatformCookiee");
}

export async function getReCaptchaSiteKeys() {
  return client.post("/auth/getReCaptchaSiteKeys");
}

export async function authenticateUser() {
  return client.get("/auth/isAuthenticated");
}
export async function internalUserPwdUpdate(payload) {
  return client.post("/auth/internalUserPwdUpdate", payload);
}
export async function redirectUser({ service }) {
  return client.get("/auth/redirectUser", {
    params: { service },
  });
}

export async function getLinkedinMe() {
  return client.get("/linkedin/me");
}

export async function downloadLinkedinProfilePicNUpload(imageUrl) {
  return client.post("/linkedin-pic-upload", { imageUrl });
}

export async function checkLinkedinExist({ memberId }) {
  return client.get(`/auth/members/exist/linkedin/${memberId}`);
}

export async function loginByLinkedIn() {
  return client.post("/auth/members/login/linkedin");
}

/**
 * SignUp User when LinkedIn signUp completed  \
 * Throws only on status >= 500
 * @param {{registrationEmail: String, firstName: String, lastName: String, contactEmail: String[], appLinkedInId: String, industry, experiences, profilePicURL}} payload
 */
export async function signUpByLinkedInCompleted(payload) {
  return client.post("/auth/members/signUpByLinkedInCompleted", payload);
}

export async function validateMagicCode(magicCode) {
  const magicCodeResonse = await client.get("/auth/validateMagicCode", {
    params: { magicCode },
  });
  return magicCodeResonse.data;
}

export async function getCircleByCode(circleCode) {
  const circle = await client.get(`profile/circleByCode`, {
    params: { circleCode },
  });
  return circle.data;
}

export async function createAccount(payload) {
  return client.post("/auth/magicLynkSignup", payload, {
    validateStatus: validRestStatus,
  });
}

/* SAML Client URLs */
export async function checkLoginMethod(email, service) {
  return client
    .post(`/auth/checkLoginMethodByEmail`, { email, service })
    .then((response) => response.data);
}
export async function redirectSAMLUserAuth(authService, redirect) {
  return client.get(
    `/external-callbacks/redirectSAMLUserAuth?authService=${authService}&redirect=${redirect}`
  );
}
/* SAML Client URLs End*/

export async function checkUserEmailStatus({
  email,
  captchaInvisible,
  source,
}) {
  return client.post("/auth/members/getLoginMethodByEmail", {
    email,
    captchaInvisible,
    source,
  });
}
export async function signUpByPassword(payload) {
  return client.post("/auth/members/signUpByPassword", payload, {
    validateStatus: validRestStatus,
  });
}
export async function saveComplianceHistory(payload) {
  return client.post("/tnc/complianceHistory", payload, {
    validateStatus: validRestStatus,
  });
}
export async function saveComplianceSetting(payload) {
  return client.post("/tnc/complianceSetting", payload, {
    validateStatus: validRestStatus,
  });
}

/** ************
 * Magic flow *
 ************* */
export async function magicAddLinkedinAndLogin(payload) {
  payload.source = payload.source ? payload.source : "platform";
  return client.post("/auth/members/magicAddLinkedinAndLogin", payload, {
    validateStatus: validRestStatus,
  });
}

export async function forgetPassword({ registrationEmail, captcha }) {
  return client.put(`/auth/forgetPassword`, {
    registrationEmail,
    captcha,
  });
}

export async function resetPassword(payload) {
  return client.post("/auth/resetPassword", payload, {
    validateStatus: validRestStatus,
  });
}

/**
 * notify CPB that invite token used
 * @param {string} teamId teamId that invited
 * @param {object} parsedData data provided from CPB
 */
export function resolveEmailInvite(teamId, parsedData) {
  return client.post(`/teams/${teamId}/invite/resolve`, parsedData);
}

const returnData = (resp) => resp.data;
/**
 * user
 */
export function getLAConfigClient() {
  return client
    .get("/auth/v4/config/client")
    .then((resp) => {
      return resp.data;
    })
    .catch((e) => {
      console.log(`getLaConfig Error`, e);
      Promise.reject(e.response.data);
    });
}

export function loginAndSetupMfa(loginId, password, mfaToken, token) {
  return client
    .post("/auth/mfa/setupMfaAndLogin", {
      email: loginId,
      password,
      mfaToken,
      token,
    })
    .then(returnData, (e) => Promise.reject(e.response.data));
}

// Mattermost Team Invite info
export function getInviteInfo(inviteId, success, error) {
  return client
    .get(`/auth/v4/teamInvite?inviteId=${inviteId}`)
    .then((inviteData) => {
      if (inviteData) {
        success(inviteData.data);
      }
    })
    .catch((err) => {
      if (error) {
        error(err);
      }
    });
}

export function createUserWithInvite(payload, success, error) {
  return client
    .post(`/auth/v4/createUser`, payload)
    .then((successResponse) => {
      success(successResponse);
    })
    .catch((err) => {
      error(err);
    });
}

export function generateMfaSecret(payload) {
  return new Promise((resolve, reject) => {
    client
      .post(`/auth/mfa/generateMfaSecret`, payload)
      .then((successResponse) => {
        resolve(successResponse);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function getEmailBySchedulingId(requestId) {
  return client.get(`/auth/getEmailBySchedulingId?requestId=${requestId}`);
}
