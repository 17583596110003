import {
  getLinkedinMe,
  checkLinkedinExist,
  loginByLinkedIn,
  downloadLinkedinProfilePicNUpload,
  signUpByLinkedInCompleted,
  magicAddLinkedinAndLogin,
} from "../api/lynkClient";

import { setCookie } from "./cookieHelper";
import { get } from "lodash";

export const loginWithLinkedInSubmit = async (action) => {
  try {
    const data = action.payload ? action.payload : {};
    const { code, state } = action;
    if (!code || !state) {
      throw new Error("We faced problem while login with LinkedIn");
    }
    const response = await getLoginInfoFromLinkedinNUploadPhoto(code, state);
    if (!response) {
      throw new Error("We faced problem while fetching profile from LinkedIn");
    }

    const { appLinkedInId } = response;
    // To check whether memberId returned from LinkedIn exists in auth/members collection
    const responseLiExist = await checkLinkedinExist({
      memberId: appLinkedInId,
    });
    let token;
    if (responseLiExist.data && responseLiExist.data.found) {
      const responseLogin = await loginByLinkedIn();
      if (!responseLogin) {
        throw new Error("We faced problem while login with Platform");
      }
      token = responseLogin.data.accessToken;
    } else {
      if (window.location.pathname === "/login") {
        throw new Error(
          "Profile does not exist (or) was not signed up via LinkedIn"
        );
      } else if (
        window.location.pathname === "/signup" ||
        window.location.pathname === `/circle/${action.circleCode}/signup`
      ) {
        const profile = {
          ...response,
          // registrationEmail is needed even for linkedin signup
          source: data.source,
          saveShortTnC: true,
        };
        if (action.circleCode) profile.circleCode = action.circleCode;
        const responseSignup = await signUpByLinkedInCompleted(profile);
        if (!responseSignup) {
          throw new Error("Invalid response from apiService.signup");
        }

        if (responseSignup && !get(responseSignup, "data.success")) {
          throw new Error(`${get(responseSignup, "data.message")}`);
        }
        token = responseSignup.data.accessToken;
      } else {
        throw new Error("We faced some problem");
      }
    }
    setCookie("LYNK_PLATFORM_TOKEN", token);
    return { success: true, token: token, errorMessage: "" };
  } catch (e) {
    return {
      success: false,
      errorMessage: get(e, "response.data.message", e.message),
    };
  }
};

/**
 * linkedin access_token assumed embed when request
 */
const getLoginInfoFromLinkedinNUploadPhoto = () =>
  getLinkedinMe()
    .then((result) => result.data)
    .then((rs) => {
      if (rs.profilePicURL) {
        return downloadLinkedinProfilePicAndUpload(rs.profilePicURL).then(
          (profilePicURL) => ({ ...rs, profilePicURL })
        );
      }
      // linkedin donot have profile pic
      return {
        ...rs,
        profilePicURL:
          "https://s3-ap-southeast-1.amazonaws.com/lynk-expert-portal/assets/avatar.png",
      };
    });

const downloadLinkedinProfilePicAndUpload = (pictureUrl) =>
  downloadLinkedinProfilePicNUpload(pictureUrl)
    .then((uploadRs) => {
      return uploadRs.data.imageLink;
    })
    .catch((err) => {});

export const signupLinkedinMagicLynk = async (action) => {
  try {
    const data = action ? action : {};
    const { code, state } = action;
    if (!code || !state) {
      throw new Error("We faced problem while login with LinkedIn");
    }
    const liResponse = await getLoginInfoFromLinkedinNUploadPhoto(code, state);
    if (!liResponse) {
      throw new Error("We faced problem while fetching profile from LinkedIn");
    }

    const profile = {
      ...liResponse,
      magicCode: data.magicCode,
      // !!!!!!!!! IMPORTANT !!!!!!!!!
      // It is madatory to combined [registrationEmail] with [magicCode] to validate the user who use this magic-lynk
      // Hacker can get magic-lynk, so providing [registrationEmail] is necessary
      registrationEmail: data.email,
      saveShortTnC: data.saveShortTnC,
    };

    const response = await magicAddLinkedinAndLogin(profile);

    if (response.data && response.data.success && response.data.accessToken) {
      setCookie("LYNK_PLATFORM_TOKEN", response.data.accessToken);
      return {
        success: true,
        token: response.data.accessToken,
        errorMessage: "",
      };
    } else {
      throw new Error("We faced problem while signing up");
    }
  } catch (e) {
    return {
      success: false,
      errorMessage: get(e, "response.data.message", e.message),
    };
  }
};
