/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const CheckBoxWithLabel = ({ name, checked, onChange, children }) => (
  <React.Fragment>
    <label className={styles.checkboxContainer}>
      <input
        checked={checked}
        type="checkbox"
        name={name}
        onChange={onChange}
      />
      <span className={styles.checkmark} />
    </label>
    <div className={styles.checkboxText}>{children}</div>
  </React.Fragment>
);

CheckBoxWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CheckBoxWithLabel;
