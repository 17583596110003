import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoginWidget from "../../components/Login";
import {
  loginUser,
  checkLoginMethod,
  redirectSAMLUserAuth,
  getCircleByCode,
  loginAndSetupMfa,
  getEmailBySchedulingId,
} from "../../api/lynkClient";

import "./login.scss";
import _ from "lodash";
import Loading from "../../components/Loading";

export default function Login(props) {
  const [loginType, setLoginType] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(true);
  const [circle, setCircle] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [mfaPayload, setMfaPayload] = useState({});
  const [failCount, setFailCount] = useState(0);
  const [defaultEmail, setDefaultEmail] = useState("");
  const navigate = useNavigate();
  const { search } = useLocation();
  let { circleCode } = useParams();

  useEffect(() => {
    setErrorMsg("");
    const query = new URLSearchParams(search);
    const redirectTo = query.get("redirect_to");
    if (redirectTo && redirectTo.includes("/consultation-request")) {
      const newQuery = new URLSearchParams(redirectTo.split("?")[1] || "");
      if (newQuery) {
        const requestId = newQuery.get("requestId");
        getEmailBySchedulingId(requestId).then((emailData) => {
          const email = _.get(emailData, "data.data.email", "");
          if (email) {
            setDefaultEmail(email);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(circleCode)) {
      getCircleByCode(circleCode)
        .then((response) => {
          setLoading(true);
          if (response.data.success) setCircle(response.data);
          else navigate(`/login${search}`);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [circleCode, navigate, search]);

  const handleMfaToken = async ({ mfaToken, token }, { email, password }) => {
    return loginAndSetupMfa(email, password, mfaToken, token).then(() => {
      props.setIsAuthenticated(true);
      setErrorMsg("");
      navigate(`/redirect${search}`);
    }, postLoginFail);
  };

  const handleLinkedInUserLogin = (result) => {
    setLoading(false);
    if (result.success) {
      props.setIsAuthenticated(true);
      navigate(`/redirect${search}`);
    } else {
      props.setIsAuthenticated(false);
    }
  };
  const postLoginFail = (err) => {
    console.log(`post login fail err`, err);
    const errResponse = err && err.response && err.response.data;
    if (errResponse.action && errResponse.action.type === "MFA_SETUP") {
      // never setup
      if (errResponse.action.payload && errResponse.action.payload.qrCode) {
        setLoginType({
          type: "setup-mfa",
        });
        setMfaPayload(errResponse.action.payload);
      } else {
        setErrorMsg("We faced problem when login");
      }
      setLoading(false);
    } else if (errResponse.action && errResponse.action.type === "MFA_FAILED") {
      setLoginType({
        type: "check-mfa",
      });
      setLoading(false);
      setErrorMsg(loginType.type === "check-mfa" ? "Invalid Token" : "");
    } else {
      const message =
        errResponse.message === "Request failed with status code 404"
          ? "Incorrect email or password, please try again"
          : "An unknown error has occured, please try again";
      setLoginType({ type: "LOGIN_BY_EMAIL_PASSWORD" });
      setFailCount(failCount + 1);
      setErrorMsg(message);
    }
  };

  const loginMfaUser = (email, password, mfaToken) => {
    const service = new URLSearchParams(search).get("service");
    loginUser(email, password, null, null, service, mfaToken)
      .then(() => {
        props.setIsAuthenticated(true);
        setErrorMsg("");
        navigate(`/redirect${search}`);
      })
      .catch((err) => {
        postLoginFail(err);
      });
  };

  const checkSSOLoginMethod = ({
    email,
    password,
    captcha,
    captchaInvisible,
  }) => {
    const service = new URLSearchParams(search).get("service");
    checkLoginMethod(email, service).then(
      ({ action: { type, url, message } }) => {
        setLoginType({
          type,
          message,
        });
        switch (type) {
          case "REDIRECT":
            const query = new URLSearchParams(search);
            const redirectTo = query.get("redirect_to");
            let href = url;
            if (redirectTo) {
              href += `?redirect=${encodeURIComponent(redirectTo)}`;
            }
            redirectSAMLUserAuth(url.split("/").pop(), href);
            window.location.href = href;
            break;
          case "LOGIN_BY_EMAIL_PASSWORD":
            if (!_.isEmpty(email) && !_.isEmpty(password)) {
              loginUser(email, password, captcha, captchaInvisible, service)
                .then(() => {
                  props.setIsAuthenticated(true);
                  setErrorMsg("");
                  navigate(`/redirect${search}`);
                })
                .catch((err) => {
                  postLoginFail(err);
                });
            }
            break;
          case "SHOW_ERROR_MESSAGE":
            setErrorMsg(message);
            break;
          default:
        }
      }
    );
  };
  if (loading) return <Loading />;
  return (
    <div className="login-page" id="login-form">
      <LoginWidget
        // onSubmit={handleUserLogin}
        loginType={loginType}
        checkLoginMethod={checkSSOLoginMethod}
        onLinkedInLogin={handleLinkedInUserLogin}
        circleObj={circle}
        defaultEmail={defaultEmail}
        errorMsg={errorMsg}
        mfaPayload={mfaPayload}
        handleMfaToken={handleMfaToken}
        submit={loginMfaUser}
        failCount={failCount}
        siteKey={props.siteKey}
      />
    </div>
  );
}
