import React from "react";

import defaultStyles from "./styles.module.scss";
import logoImage from "../../images/logo.svg";
import FooterCopy from "../FooterCopy";
const WizardFrame = ({
  children,
  title,
  subtitle,
  wide,
  ultrawide,
  contentStyle: customContentStyle,
}) => {
  const mergedStyles = defaultStyles;
  const titleStyle = subtitle
    ? mergedStyles.headerTitle
    : mergedStyles.headerTitleSingle;
  const contentStyle = ultrawide
    ? mergedStyles.contentColumnUltraWide
    : wide
    ? mergedStyles.contentColumnWide
    : mergedStyles.contentColumn;
  return (
    <div className="login-error-msg">
      <div className={contentStyle} style={customContentStyle}>
        <div className={mergedStyles.header}>
          <img src={logoImage} alt="" />
          <div className={titleStyle}>{title}</div>
          {subtitle && (
            <div className={mergedStyles.headerSubTitle}>{subtitle}</div>
          )}
        </div>
        <div className={mergedStyles.fieldsWrapper}>{children}</div>
      </div>
      <FooterCopy />
    </div>
  );
};
export default WizardFrame;
