import React from "react";
import styles from "./styles.module.scss";
import lynkLogo from "../../images/signup/lynk-neutral-logo.svg";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <img src={lynkLogo} alt="logo" />
    </div>
  );
};

export default Footer;
